<template>
  <div ref="el" class="bg-white">
    <div
      class="px-sm sm:px-md md:px-[120px] pt-[53px] pb-xl"
      :class="{
        '!pt-0': dialogStore.headline && isInDialog,
      }"
    >
      <Form
        id="reset-legacy-password-form"
        headline="resetLegacyPassword.headline"
        submit-label="resetLegacyPassword.submit"
        info="resetLegacyPassword.info"
        :is-loading="isLoading"
        @submit="onSubmit"
      >
        <template #errors>
          <div v-if="errors.length">
            <template v-if="errors.includes(ErrorIdent.PASSWORD_HASH_EXPIRED)">
              <ErrorFrame>
                {{ t('resetLegacyPassword.hashExpired') }}
              </ErrorFrame>
            </template>
            <template v-if="errors.includes(ErrorIdent.UNKNOWN_ERROR)">
              <UnknownError />
            </template>
          </div>
        </template>
        <template #default>
          <PasswordValidation
            label="resetLegacyPassword.label"
            confirm-label="resetLegacyPassword.confirmLabel"
          />
        </template>
      </Form>
    </div>
  </div>
</template>
<script setup lang="ts">
import PasswordValidation from '~/components/formFields/passwordValidation/vdv/passwordValidation.vue';
import Form from '~/components/formFields/form/form.vue';
import { useResetLegacyPasswordForm } from '../useResetLegacyPassword';
import UnknownError from '~/components/formFields/errors/unknown/unknownError.vue';
import ErrorFrame from '~/components/formFields/components/errorFrame/errorFrame.vue';
import { ErrorIdent } from '~/@types/errorIdents';
import { useDialogStore } from '@/stores/useDialogStore';

const { t } = useTrans();

const props = defineProps({
  hash: {
    type: String,
    required: true,
  },
});
const emit = defineEmits<{
  (e: 'success'): void;
}>();
const dialogStore = useDialogStore();
const el = ref<HTMLElement | null>(null);

const isInDialog = useIsInDialog(el);
const { showSuccess, isLoading, onSubmit, errors } =
  await useResetLegacyPasswordForm(props.hash);

watch(showSuccess, (val) => {
  if (val) emit('success');
});
</script>
